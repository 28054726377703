<template>
    <div class="lgame-check-box-container d-flex justify-content-start align-items-center my-1" @click="handleCheck">

        <div class="check-box-mark mr-2 d-flex justify-content-center align-items-center"
            :class="{ 'is-active': checked }">
            <div class="check-mark-icon d-flex justify-content-center align-items-center w-75 h-75" v-if="checked">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                    <path id="check-solid"
                        d="M5.435,76.863l-5.2-5.23a.808.808,0,0,1,0-1.138l1.131-1.138a.8.8,0,0,1,1.131,0L6,72.88l7.5-7.546a.8.8,0,0,1,1.131,0l1.131,1.138a.808.808,0,0,1,0,1.138l-9.2,9.253a.8.8,0,0,1-1.131,0Z"
                        transform="translate(0 -65.099)" fill="#fff" />
                </svg>
            </div>
        </div>
        <div class="check-box-label">
            <slot class="m-0 p-0"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "lgame-check-box",
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checked: this.selected,
        }
    },
    methods: {
        handleCheck() {
            this.checked = !this.checked;
            this.$emit('checked', this.checked);
            console.log('check box checked')
        }
    }
}
</script>

<style scoped>
.lgame-check-box-container {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    width: fit-content;
}

.check-box-mark {
    width: 14px;
    height: 14px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%);
    border: unset;
    border-radius: 0;
}

.check-box-mark.is-active {
    width: 14px;
    height: 14px;
    background-color: red;
    border: unset;
    border-radius: 0;
}

.check-box-label {
    color: white;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}
</style>
