<template>
    <div class="lgame-navbar-home-container  container-fluid px-0">
        <nav class="navbar navbar-expand-md navbar-dark shadow-sm">
            <div
                class="lgame-nav-bar-content container-fluid d-flex justify-content-between align-items-center px-5 py-0">
                <a class="navbar-brand" href="https://lgame.gg" target="_blank">
                    <img alt="" class="lgame-brand" width="200px" height="36px" :src="require('@/assets/Lgame_logo.svg')" />
                </a>
                <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#lgameNavBar"
                    aria-controls="lgameNavBar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar top-bar"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                </button>
                <div class="collapse navbar-collapse align-middle px-4 pr-2 px-md-0 py-5 py-md-0" id="lgameNavBar"
                    :class="{ 'show-reverse': isAuthenticated }">
                    <!-- Left Side Of Navbar -->
                    <div class="navbar-nav mr-auto mt-2 mt-md-0 px-2 px-md-0">
                        <a href="https://lgame.gg/" target="_blank" class="nav-item nav-link" exact>
                            Accueil
                        </a>
                        <a href="https://lgame.gg/tournois" target="_blank" class="nav-item nav-link" exact>
                            Tournois
                        </a>
                        <a href="https://lgame.gg/organiser" target="_blank" class="nav-item nav-link" exact>
                            Organiser
                        </a>
                    </div>

                    <!-- Right Side Of Navbar -->
                    <div class="navbar-nav ml-auto px-2 px-md-0" :class="{ 'is-auth': isAuthenticated }">
                        <!-- Authentication Links -->
                        <div class="nav-item d-flex justify-content-start justify-content-md-center align-items-center"
                            v-if="!isAuthenticated">
                            <a class="nav-link" type="button" @click="triggerLogin(false)" data-toggle="modal"
                            data-target="#loginModal">Register</a>
                        </div>
                        <div class="nav-item log-in-button-container-wrapper d-flex justify-content-start justify-content-md-center align-items-center ml-md-2"
                            data-toggle="modal" data-target="#loginModal" v-if="!isAuthenticated">
                            <div class="log-in-button-container d-flex justify-content-center align-items-center px-3"
                                @click="triggerLogin(true)">
                                <div class="icon-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g transform="translate(-0.377 -2.078)">
                                            <circle cx="12" cy="12" r="12" transform="translate(0.377 2.078)"
                                                fill="#010213" opacity="0.2" />
                                            <g transform="translate(5.066 5.493)">
                                                <ellipse cx="4" cy="4.5" rx="4" ry="4.5"
                                                    transform="translate(3.311 -0.415)" fill="#fff" />
                                                <path
                                                    d="M108.812,300.088a9.564,9.564,0,0,1-7.312-3.4,5.564,5.564,0,0,1,4.916-2.984,1.221,1.221,0,0,1,.354.055,6.222,6.222,0,0,0,4.082,0,1.221,1.221,0,0,1,.354-.055,5.564,5.564,0,0,1,4.916,2.984A9.564,9.564,0,0,1,108.812,300.088Z"
                                                    transform="translate(-101.5 -282.92)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <a class="nav-link log-in-button py-0 m-0 text-white" type="button" data-toggle="modal"
                                    data-target="#loginModal">
                                    Log In
                                </a>
                            </div>
                        </div>

                        <div class="nav-item d-flex justify-content-end align-items-center mt-2 mt-md-0"
                            v-if="isAuthenticated">
                            <LgameNavBarUserCard></LgameNavBarUserCard>
                        </div>

                    </div>
                </div>
            </div>
        </nav>
        <LgameLogin 
            :login="isLogin" 
            :action-after="login_afterAction" 
            @hide-login="closeLogin"
            v-if="initLogin" 
        >
        </LgameLogin>
    </div>
</template>
<script>
import LgameNavBarUserCard from "./LgameNavBarUserCard";
import axios from 'axios';
import LgameLogin from './LgameLogin';
export default {
    components: {
       LgameLogin,
        LgameNavBarUserCard
    },
    data() {
        return {
            isLoading: true,
            isLogin: true,
            initLogin: false,
            login_afterAction: null,
        }
    },
    computed: {
        isAuthenticated: {
            get() {
                return this.$store.state.currentUser.isAuthenticated;
            }
        },
        loggedUser: {
            get() {
                return this.$store.state.currentUser.user.name;
            }
        },
        isOrganizer: {
            get() {
                return !!this.$store.state.currentUser.user?.organizer;
            }
        }
    },
    methods: {
        checkAuth() {
            if (localStorage.getItem('lgame_userData')) {
                const currentUserData = JSON.parse(localStorage.getItem('lgame_userData'));
                axios.defaults.headers.common["Authorization"] = "Bearer " + currentUserData.accessToken;
                this.$store.dispatch('currentUser/setCurrentUser', currentUserData.userId, currentUserData.accessToken);
            }
        },
        triggerLogin(isLogin) {
            this.isLogin = isLogin;
            this.initLogin = true;
        },
        closeLogin() {
            this.initLogin = false;
        },
        goOrganize() {
            if (this.isOrganizer) {
                window.location = process.env.MIX_ORGANIZER_URL + 'organiser';
            } else {
                this.$router.push({ name: 'organiser' });
            }
        }

    },
    beforeCreate() {
        //console.log('before create: ');
        //console.log(this.$store.state.currentUser.isAuthenticated);

    },
    created() {
        //console.log('created');
        //this.checkAuth();
        //console.log('isAuthenticated: '+ this.isAuthenticated);
        this.$root.$on('trigger-register', () => {
           
            this.triggerLogin(false);
        });

    },
    mounted() {
        
        this.$root.$on('show-login', (action) => {
           
            this.initLogin = true;
            this.triggerLogin(true);
            this.login_afterAction = action;
        })
    }
}
</script>
<style scoped>
.lgame-navbar-home-container {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ml-user-select: none;
    position: sticky;
    z-index: 1070;
    top: 0;
    left: 0;
    background-color: #00171F !important;
}

.log-in-button-container-wrapper {
    cursor: pointer;
}

.log-in-button-container {
    color: white !important;
    background-color: #ff0000;
    height: 28px;
    width: fit-content;
    clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
}

.lgame-nav-bar-content {
    position: relative;
}

.navbar {
    border-bottom: 2px solid rgba(1, 13, 17, 0.93);
}


.router-link-exact-active,
.router-link-active {
    color: white !important;
}

/* style navbar collapse overriding*/
.navbar-dark .navbar-toggler {
    border: unset !important;
    outline: none !important;
}

.navbar-toggler:active {
    outline: none !important;
    border: none !important;
}

.navbar-toggler.collapsed~.navbar-collapse {
    transition: right 500ms ease-in-out;
}

.collapsing {
    height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);
    clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);
    position: absolute;
    top: 56px;
    right: -100vw;
    overflow: hidden !important;
    width: 90vw !important;
    background-color: #00171F;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
}

.navbar-collapse.show:before {
    content: ' ';
    width: 20px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 15px;
    background-color: red;
    z-index: -1;
}

.navbar-collapse.show:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
    overflow: hidden !important;
    overflow: unset !important;
    width: 90vw !important;
    background-color: #00171F;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);
    clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);
    z-index: -1;
}

.navbar-collapse.show {
    position: absolute;
    top: 56px;
    right: -1rem;
    height: calc(calc(var(--vh, 1vh) * 100) - 60px) !important;
    overflow: hidden !important;
    overflow: unset !important;
    width: 90vw !important;
    /*background-color: #00171F;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);
        clip-path: polygon(100% 0, 100% 100%, 20px 100%, 20px calc(100% - 140px), 0 calc(100% - 160px), 0 80px, 20px 60px, 20px 0);*/
    transition: right 300ms ease-in-out;
    /**/
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
}

.navbar-collapse.show.show-reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end !important;
    align-items: center;
    padding-right: 0 !important;
    padding-top: 0 !important;
}

.collapsing.show-reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end !important;
    align-items: center;
    padding-top: 0 !important;
}

.navbar-collapse.show.show-reverse>.navbar-nav.is-auth:last-child {
    display: flex;
    flex-direction: column-reverse !important;
}

.navbar-collapse.show>.navbar-nav:not(.is-auth):last-child {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
}

.closer-div {
    visibility: hidden;
}

.navbar-toggler:not(.collapsed)~.closer-div {
    visibility: visible !important;
    content: ' ';
    background: red;
    width: 20px;
    height: 160px;
    position: absolute;
    top: 56px;
    left: 20px;
    transition: visibility 500ms ease-in-out;
}
.nav-link{
    display: block;
    padding: 0.5rem 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}



/* style navbar overriding */
@media only screen and (max-width: 600px) {
    .lgame-nav-bar-content {
        padding: unset !important;
    }

    .lgame-brand {
        width: 100px;
        height: 32px;
    }

    .lgame-nav-bar-content {
        position: relative;
        filter: drop-shadow(-4px 0px 4px rgba(0, 0, 0, 0.7));
    }
}

@media only screen and (max-width: 680px) {}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .lgame-nav-bar-content {
        padding: unset !important;
    }

    .lgame-brand {
        width: 160px;
        height: 34px;
    }

    .lgame-nav-bar-content {
        filter: drop-shadow(-4px 0px 4px rgba(0, 0, 0, 0.7));
    }
}


/*navbar toggler custom */
.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
}

.navbar-dark .navbar-toggler .icon-bar {
    background: #E9E9E9;
}

.navbar-toggler .icon-bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.navbar-toggler .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .icon-bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
    transform: rotate(0);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
    transform: rotate(0);
}
</style>
