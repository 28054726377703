<template>
    <div class="lgame-navbar-user-card-container" @mouseover="showCardElts" @mouseleave="hideCardElts"
        @click="toggleCard" v-show="!isLoading">
        <div class="navbar-user-card-content" :class="{ 'dropdown-active': showCard }">
            <div class="user-avatar-container">
                <img width="24px" alt="" :src="avatarDir + userAvatar" @error.once="imageNotFound($event, 'avatar')" />
            </div>
            <transition name="slide-fade">
                <div class="card-content-aside ml-2 mr-4" v-if="showCard">
                    <div class="user-name-container">
                        <div class="user-name text-white">
                            {{ userName }}
                        </div>
                    </div>
                </div>
            </transition>
            <div class="dropdown-arrow" :class="{ 'dropdown-arrow-after': showCard }" @click="toggleCard">
                <img alt="" :src="arrowIcon" />
            </div>
        </div>
        <transition name="slide-fadeY">
            <div class="drop-down-block" v-show="showCard">
                <div class="drop-down-content p-0">
                    <div class="user-brands">
                        <div class="user-brand-banner-container">
                            <img alt="" class="user-brand-banner-image" width="100%" height="100%"
                                :src="bannerDir + userBanner" @error.once="imageNotFound($event, 'banner')" />
                        </div>
                        <div class="user-brand-avatar-container" @click="goProfile">
                            <div class="user-brand-avatar-wrapper mr-2">
                                <div class="user-brand-avatar">
                                    <img alt="" width="100%" height="100%" :src="avatarDir + userAvatar"
                                        @error.once="imageNotFound($event, 'avatar')">
                                </div>
                            </div>
                            <div class="user-brand-username">{{ userName }}</div>
                        </div>
                    </div>
                    <ul class="lgame-drop-down-menu m-0 my-2 px-4 pt-4 pb-0 text-left">
                        <li class="lgame-drop-down-item mb-2" @click="dashboard()">
                            Tableau de bord
                        </li>
                        <li class="lgame-drop-down-item mb-2" @click="goOrganize()"
                            v-if="checkEligibility('organizer')">
                            Organiser
                        </li> <!-- if user is an organizer -->
                        <li class="lgame-drop-down-item mb-2" @click="goTournaments()"
                            v-if="checkEligibility('organizer')">
                            Mes tournois
                        </li> <!-- if user is an organizer -->
                        <li class="lgame-drop-down-item mb-2" @click="goParticipations()"
                            v-if="checkEligibility('player')">
                            Mes participations
                        </li> <!-- if user is a player -->
                        <li class="lgame-drop-down-item mb-2" @click="profileSettings()">
                            Paramètres du profil
                        </li>
                        <li class="lgame-drop-down-item log-out-button mt-4" @click="logOutUser">
                            <span v-if="!loggingOut">Se déconnecter</span>
                            <span v-if="loggingOut">Déconnexion ...</span>
                        </li>
                    </ul>
                    <div class="drop-down-block-footer">
                        <!-- to show a red footer -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable */
import arrowIcon from '@/assets/drop-down-arrow.png';
import logOutIcon from '@/assets/log-out-icon.png'
export default {
    name: "LgameNavBarUserCard",
    data() {
        return {
            arrowIcon: arrowIcon,
            logOutIcon: logOutIcon,
            showCard: false,
            mouseOnCard: false,
            isLoading: true,
            array_roles: [],
            loggingOut: false,
            storageDir: 'https://lgame.gg/uploads',

        }
    },
    computed: {
        userLoaded: {
            get() {
                return !!this.$store.state.currentUser.user;
            }
        },
        userName: {
            get() {
                return this.userLoaded && this.isOnlyOrganizer ?
                    this.$store.state.currentUser.user.organizer.name : this.$store.state.currentUser.user.player.username;
            }
        },
        userAvatar: {
            get() {
                return this.userLoaded && this.isOnlyOrganizer
                    ? (this.$store.state.currentUser.user.organizer.avatar ? this.$store.state.currentUser.user.organizer.avatar : null)
                    : (this.$store.state.currentUser.user.player.avatar ? this.$store.state.currentUser.user.player.avatar : null); // I should add a placeholder in case no avatar
            }
        },
        userBanner: {
            get() {
                return this.userLoaded && this.isOnlyOrganizer
                    ? (this.$store.state.currentUser.user.organizer.banner ? this.$store.state.currentUser.user.organizer.banner : null)
                    : (this.$store.state.currentUser.user.player.banner ? this.$store.state.currentUser.user.player.banner : null); // I should add a placeholder in case no avatar
            }
        },
        arr_roles: {
            get() {
                return this.$store.state.currentUser.roles_array;
            }
        },
        isOnlyOrganizer: {
            get() {
                if (this.arr_roles.length > 0) {
                    return this.arr_roles.indexOf('organizer') !== -1 && this.arr_roles.length === 1;
                }
            }
        },
        avatarDir: {
            get() {
                return this.userLoaded && this.isOnlyOrganizer
                    ?'https://lgame.gg/images/' + 'organizers/avatars/'
                    : 'https://lgame.gg/images/' + 'players/avatars/'
            }
        },
        bannerDir: {
            get() {
                return this.userLoaded && this.isOnlyOrganizer
                    ? 'https://lgame.gg/images/' + 'organizers/banners/'
                    : 'https://lgame.gg/images/' + 'players/banners/';
            }
        }
    },

    methods: {
        showCardElts() {
            if (this.showCard !== true) {
                this.mouseOnCard = true;
                this.showCard = true;
            }
        },
        hideCardElts() {
            if (this.showCard === true) {
                this.mouseOnCard = false;
                this.showCard = false;
            }
        },
        dashboard() {
            if (this.arr_roles.indexOf('organizer') !== -1) {
                window.location = "https://organizer.lgame.gg/";
            }
            if (this.arr_roles.indexOf('player') !== -1) {
                window.location = "https://player.lgame.gg/";
            }
        },
        goProfile() {
            if (this.arr_roles.indexOf('organizer') !== -1) {
                window.location = "https://organizer.lgame.gg/";
            }
            if (this.arr_roles.indexOf('player') !== -1) {
                window.location = "https://player.lgame.gg/";
            }
        },
        profileSettings() {
            if (this.arr_roles.indexOf('organizer') !== -1) {
                window.location = "https://organizer.lgame.gg/profile";
            }
            if (this.arr_roles.indexOf('player') !== -1) {
                window.location = "https://player.lgame.gg/profile/settings";
            }
        },
        goTournaments() {
            const access_token = JSON.stringify({
                'accessToken': this.$store.state.currentUser.accessToken,
            });
            window.location = "https://organizer.lgame.gg/tournois";
            //localStorage.setItem('access_token', access_token);
        },
        goOrganize() {
            window.location = "https://organizer.lgame.gg/organiser";
        },
        goParticipations() {
            window.location = "https://player.lgame.gg/participations";
        },
        logOutUser() {
            this.loggingOut = true;
            this.$store.dispatch('currentUser/logOutUser', { redirect: false })
                .then(response => {
                    console.log('returning response from user card ====================>')
                    console.log(response)
                    console.log('returning response from user card ====================>')
                    this.loggingOut = false;
                    if (window.location.host.split('.')[0] === 'player' || window.location.host.split('.')[0] === 'organizer') {
                        window.location = 'https://electroplanet.lgame.gg';
                    }
                });
        },
        /**/
        checkEligibility(role) {
            return this.arr_roles.indexOf(role) !== -1;
        },
        toggleCard() {
            if (this.showCard === true) {
                this.hideCardElts()
            } else {
                this.showCardElts()
            }
            //this.showCard = !this.showCard;
        },
        imageNotFound(e, imageWho) {
            if (imageWho === 'avatar') {
                e.target.src = "https://lgame.gg/images/avatar-error-placeholder.png";
            }
            if (imageWho === 'banner') {
                e.target.src = "https://lgame.gg/images/banner-error-placeholder.png";
            }
        },
    },
    created() {
        if (this.arr_roles)
            console.log('User card created');
    },
    mounted() {
        this.isLoading = false;
        console.log('User Card mounted');
        console.log(this.arr_roles);
        console.log('User Card mounted end');

    }
}
</script>

<style scoped>
.lgame-navbar-user-card-container {
    position: relative;
    min-width: 48px;
    width: fit-content;

}

.navbar-user-card-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ff0000;
    /*clip-path: polygon(8px 0, 100% 0, 100% 100%,0 100%, 0 8px);*/
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.dropdown-active {
    transition: .9s;
}

.user-avatar-container {
    width: 46px;
    height: 36px;
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.user-avatar-container img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    object-position: center;
    filter: drop-shadow(2px 2px 2px black);
}

.card-content-aside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.user-name-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dropdown-arrow {
    width: 16px;
    aspect-ratio: 1;
    transform: rotate(90deg);
    transition-duration: .4s;
}

.dropdown-arrow-after {
    transform: rotate(0deg);
    transition-duration: .3s;
    margin-inline: 2px;
}

.drop-down-block {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    right: 8px;
    margin-top: 4px;
    z-index: 10;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.6));
}

.drop-down-content {
    background: #00171F;
    color: white;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 2px 100%, 0 calc(100% - 2px));
}

.user-brands {
    position: relative;
    height: 160px;
    width: 320px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.8));
}

.user-brand-banner-container {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
}

.user-brand-banner-container img {
    object-fit: fill;
}

.user-brand-avatar-container {
    position: absolute;
    z-index: 5;
    left: 8px;
    bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.user-brand-avatar-container:hover {
    transform: scale(1.01);
}

.user-brand-avatar-wrapper {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.8));
}

.user-brand-avatar {
    width: 46px;
    height: 36px;
    background-color: #ff0000;
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.user-brand-username {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.drop-down-content ul {
    list-style-type: none;
}

.drop-down-content ul li {
    color: #9A9A9A;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    width: auto;
}

.drop-down-content ul li:hover {
    transform: scale(1.01);
    color: white;
}

.lgame-drop-down-item {}

.log-out-button {
    background-color: #ff0000;
    color: white !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding: 4px;
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.drop-down-block-footer {
    width: 100%;
    height: 2px;
    background-color: #ff0000;
}









/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

/**/
.slide-fadeY-enter-active {
    transition: all .3s ease;
}

.slide-fadeY-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fadeY-enter,
.slide-fadeY-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateY(-10px);
    opacity: 0;
}

@media only screen and (max-width: 360px) {
    .user-brands {
        width: 86vw;
        max-width: 300px;
    }
}
</style>
